@import "variables.scss";

html, body {
    height:100%;
}

body {
    background-color: var(--main-background);
    display:flex;
    flex-direction: column;
}

div.main-page, div.main-page-description {
    width:100%;
    color:var(--main-text-color);
}
    
div.main-page {
    padding:2em 2em 0 2em;

    .main-page-text {
        font-size:3.7em;
        line-height: 1em;
        font-weight:bolder;

        span {
            color:var(--secondary-color);
            animation: name-color-change 6s;
        }
    }
}

div.main-page-description {
    padding:0.4em 1em 0 1em;
    font-size:1.6em;
    line-height:1em;
}

.title {
    color: var(--main-text-color);
}

div.card {
    background-color: var(--card-background-color);
}

@keyframes name-color-change {
    0% { color: var(--main-text-color); }
    100% { color: var(--secondary-color); }
}
